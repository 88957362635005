import { Link } from "gatsby"
import * as React from "react"

const CallToAction = () => {
    return (
        <section className="bg-gradient-to-b from-black to-pink-custom">
            <div className="container text-center md:mx-auto pt-4 md:pt-8 pb-6 md:pb-10 max-w-page">
                <h2 className="text-white text-3xl md:text-4xl font-alegreya">Contact Richard Jones</h2>                
                <p className="text-gray-200 text-xl md:text-2xl font-light md:w-4/5 md:mx-auto">If you would like to get in touch to discuss how I can help you, please click the button below.</p>
                <p className="pt-4 md:pt-8">
                    <Link to="/contact" className="inline-block text-xl md:text-2xl uppercase shadow-sm transition-all duration-200 border border-1 border-pink-700 md:border-pink-900 md:hover:border-pink-700 rounded py-2 px-4 md:px-8 bg-pink-900 md:hover:bg-white text-white font-bold md:hover:text-pink-700">Get in touch</Link>
                </p>          
            </div>
        </section>
    )
}

export default CallToAction