import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import CallToAction from "../components/CallToAction/CallToAction"
import Layout from "../components/layout"
import Seo from "../components/seo"

const HomePage = ({ location }) => {
	return (
		<Layout location={location}>
			<article>
				<div className="container md:mx-auto max-w-page py-4 md:py-8">
					<StaticImage 
						src="../images/richard-jones.png"
						alt="Richard Jones - Gestalt LGBT+ / ASD Psychotherapist"
						quality={100}
						className="border-2 border-gray-400 rounded float-right w-2/5 xs:w-1/3 ml-4 xs:ml-8 mb-4 xs:mb-0"
						imgClassName="border-2 border-white rounded"
					/>					
					<h1 className="text-3xl md:text-4xl font-alegreya mb-0">Richard Jones</h1>
					<h2 className="text-lg md:text-xl text-gray-800">Gestalt LGBTQIA+ / ASD Psychotherapist</h2>							
					<p className="text-gray-600 md:text-lg">Acceptance Counselling specialises in one to one therapy for those across the LGBT and the autistic spectrum. I am based in the UK in Hampshire, but able to work nationally online. I have experience in working with those with complex emotional, physical, and cognitive rehabilitation health needs.</p>
					<p className="text-gray-600 md:text-lg">I work as a Gestalt integrative psychotherapist with 14 years experience in counselling. I work with issues including, though not limited to anxiety, depression, bereavement, and addiction. I am also able to provide clinical supervision.</p>
					{/* <p className="text-gray-600 md:text-lg"><strong>Although I do not currently charge a fee for my services, I do request that clients make a donation of at least &pound;25 per hour of my time to <a href="https://www.hallcounsellingservice.co.uk/about-6" className="text-pink-700 underline md:no-underline md:hover:underline" target="_blank">Hall Counselling Service low cost community counselling</a>, to support those who would not otherwise be able to access therapy.</strong></p> */}
					<p className="text-gray-600 md:text-lg"><strong>Acceptance Counselling is a small community not for profit charitable organisation working solely with volunteers. Donations from &pound;20 - &pound;45 per session enable its operation and access to therapy to those would would be otherwise unable to afford access to counselling.</strong></p>
					<p className="text-gray-600 md:text-lg">Of particular interest to me is working with the Trans community and providing holistic support to explore transition, gender and sexuality as appropriate. I have maintained working in this area since qualification.</p>
					<p className="text-gray-600 md:text-lg">Assessment and ongoing support will be provided via the Zoom application and telephone, or whichever audio-visual format is mutually most appropriate or available. Face to face sessions can be offered to those living in the Hampshire area.</p>
					{/* <p className="text-gray-600">Richard Jones is a registrant on the BACP Register of Counsellors & Psychotherapists.</p> */}
				</div>
			</article>
			<CallToAction/>
		</Layout>
	)
}

export const Head = () => (
	<Seo title="Home" />
)

export default HomePage